<template lang="pug">
app-layout
  .page-dataroom
    ui-loader(big)
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';

  import { UiLoader } from '@/uikit';
  import { service as authService } from '@/services/auth/auth-service';

  export default {
    name: 'page-dataroom',
    components: { AppLayout, UiLoader },

    mounted() {
      this.redirectToDataRoom();
    },

    methods: {
      async redirectToDataRoom() {
        try {
          const user = localStorage.getItem('ColibriUser') ? JSON.parse(localStorage.getItem('ColibriUser')) : undefined;
          if (user && user.id) {
            const data = await authService.getUriToDataRoom();
            if (data && data.redirect_uri) {
              const redirectPath = data.redirect_uri;
              window.location.href = redirectPath;
            }
          } else {
            localStorage.setItem('redirectToDataRoom', true);
            window.location.pathname = '/auth/sign_in';
          }
        } catch (error) {
          logger.log(error);
          localStorage.setItem('redirectToDataRoom', true);
          window.location.pathname = '/auth/sign_in';
        }
      }
    }
  };
</script>

<style lang="scss">
  .page-dataroom {
    background-color: c('light', 'light');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    height: 100vh;
    width: 100%;
  }
</style>
